import React, { useRef, useState } from 'react';
import { Box, Typography, Avatar, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
// import MyProperties from './MyProperties/MyProperties';
import { Gallery } from '../../../components/gallery/Gallery';
import { Note } from '../../../assets/svg/Note';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MyContact from './MyContact/MyContact';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import Notes from './Notes/Notes';
import Subscription from './Subscription/Subscription';
import BillingHistory from './BillingHistory/BillingHistory';
import { useDispatch } from 'react-redux';
import { updateProfileAvatar } from '../../../store/slices/accountSlice';
import { useTranslation } from 'react-i18next';
import Support from '../../../pages/Support/Support';
import Terms from '../../../pages/Terms/Terms';
import Policy from '../../../pages/Policy/Policy';
import SearchProperty from '../../../pages/SearchProperty/SearchProperty';
import MyViewings from './MyViewings/MyViewings';
import { Helmet } from 'react-helmet-async';

const Sidebar = styled(Box)(({ theme }) => ({
  width: 250,
  minWidth: '250px',
  padding: theme.spacing(3),
  borderRight: `1px solid ${theme.palette.divider}`
}));

const NavItem = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 80,
  height: 80,
  margin: 'auto',
  marginBottom: theme.spacing(1),
  '&:hover .avatarOverlay': {
    opacity: 1
  }
}));

const AvatarOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  opacity: 0,
  transition: 'opacity 0.3s'
}));

const MyAccount = ({ data }) => {
  const [gallery, setGallery] = useState([
    {
      images: [
        'https://plus.unsplash.com/premium_photo-1723901831135-782c98d8d8e0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Sonnenweg 15, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 125,
      id: 10
    },
    {
      images: [
        'https://images.unsplash.com/photo-1523755231516-e43fd2e8dca5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Seestrasse 42, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 120,
      id: 2
    },
    {
      images: [
        'https://plus.unsplash.com/premium_photo-1663126298656-33616be83c32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Blumenweg 8, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 130,
      id: 3
    },
    {
      images: [
        'https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Kirchgasse 19, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 120,
      id: 4
    }
  ]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const searchTerm = queryParams.get('section') || 'property';
  const avatarRef = useRef();
  const [avatarHovered, setAvatarHovered] = useState(false);

  const handleClick = (link) => {
    navigate(link);
  };

  const onAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(updateProfileAvatar(file));
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar>
        <Box sx={{ mt: '25px' }}>
          <Typography sx={{ color: '#202020', fontWeight: 500, fontSize: '16px' }}>
            {t('Search Property')}
          </Typography>
          <Box
            sx={{
              marginTop: '32px',
              marginBottom: '32px',
              border: '1px solid #ddd'
            }}
          />
        </Box>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <AvatarWrapper
            onMouseEnter={() => setAvatarHovered(true)}
            onMouseLeave={() => setAvatarHovered(false)}>
            <IconButton
              sx={{
                width: '100%',
                height: '100%',
                padding: 0
              }}
              onClick={() => avatarRef.current.click()}>
              <Avatar
                src={data?.avatar_url ? data.avatar_url : 'https://via.placeholder.com/100'}
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              />
              <AvatarOverlay className="avatarOverlay">
                <CameraAltIcon sx={{ color: 'white' }} />
              </AvatarOverlay>
            </IconButton>
          </AvatarWrapper>
          <input
            accept="image/png, image/jpeg, image/svg+xml"
            onChange={onAvatarUpload}
            ref={avatarRef}
            type="file"
            hidden
          />
          <Box
            onClick={() => handleClick('/edit-profile')}
            sx={{
              textAlign: 'center',
              mb: 2,
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer'
            }}>
            <Typography variant="h6">{`${data.firstname} ${data.lastname}`}</Typography>
            <Box sx={{ marginLeft: '12px', marginTop: '8px' }}>
              <Note />
            </Box>
          </Box>
          {data.country && (
            <Typography variant="body2" color="text.secondary">
              {data.country}
            </Typography>
          )}
        </Box>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          fullWidth
          color="primary"
          sx={{
            backgroundColor: 'transparent',
            color: '#1C2024',
            borderColor: '#ddd',
            marginBottom: '40px'
          }}
          onClick={() => handleClick('/chat')}>
          {t('New chat')}
        </Button>
        <NavItem
          onClick={() => handleClick('/my-account?section=searches')}
          variant="body1"
          color={searchTerm.includes('searches') ? 'primary' : ''}>
          {t('My Searches')}
        </NavItem>
        <NavItem
          onClick={() => handleClick('/my-account?section=viewings')}
          variant="body1"
          color={searchTerm.includes('viewings') ? 'primary' : ''}>
          {t('My Viewings')}
        </NavItem>
        <NavItem
          onClick={() => handleClick('/my-account?section=contact')}
          variant="body1"
          color={searchTerm.includes('contact') ? 'primary' : ''}>
          {t('Contact Manager')}
        </NavItem>
        {/* <NavItem
          onClick={() => handleClick('/my-account?section=notes')}
          color={searchTerm.includes('notes') ? 'primary' : ''}
          variant="body1">
          {t('Notes')}
        </NavItem> */}
        <NavItem
          onClick={() => handleClick('/my-account?section=subscription')}
          color={searchTerm.includes('subscription') ? 'primary' : ''}
          variant="body1">
          {t('Subscription')}
        </NavItem>
        {/* <NavItem
          onClick={() => handleClick("/my-account?section=payments")}
          color={searchTerm.includes("payments") ? "primary" : ""}
          variant="body1"
        >
          Payments
        </NavItem> */}
        <NavItem
          onClick={() => handleClick('/my-account?section=billing-history')}
          color={searchTerm.includes('billing-history') ? 'primary' : ''}
          variant="body1">
          {t('Billing History')}
        </NavItem>
      </Sidebar>
      {
        // searchTerm === 'property' ? (
        //   <MyProperties />
        // ) :
        // searchTerm === 'notes' ? (
        //   <Notes userId={data.id} />
        // ) :
        searchTerm === 'billing-history' ? (
          <>
            <Helmet>
              <title>{t('Billing History')}</title>
            </Helmet>
            <BillingHistory />
          </>
        ) : searchTerm === 'subscription' ? (
          <>
            <Helmet>
              <title>{t('Subscription')}</title>
            </Helmet>
            <Subscription />
          </>
        ) : searchTerm === 'support' ? (
          <>
            <Helmet>
              <title>{t('Support')}</title>
            </Helmet>
            <Support />
          </>
        ) : searchTerm === 'terms' ? (
          <>
            <Helmet>
              <title>{t('Terms')}</title>
            </Helmet>
            <Terms />
          </>
        ) : searchTerm === 'policy' ? (
          <>
            <Helmet>
              <title>{t('Policy')}</title>
            </Helmet>
            <Policy />
          </>
        ) : searchTerm === 'search' ? (
          <>
            <Helmet>
              <title>Searches</title>
            </Helmet>
            <SearchProperty />
          </>
        ) : searchTerm === 'searches' ? (
          <>
            <Helmet>
              <title>{t('My Searches')}</title>
            </Helmet>
            <Gallery gallery={gallery} />
          </>
        ) : searchTerm === 'viewings' ? (
          <>
            <Helmet>
              <title>{t('My Viewings')}</title>
            </Helmet>
            <MyViewings />
          </>
        ) : (
          // : searchTerm === "payments" ? (
          //   <Payments />
          // )
          <>
            <Helmet>
              <title>{t('Contact Manager')}</title>
            </Helmet>
            <MyContact userId={data.id} />
          </>
        )
      }
    </Box>
  );
};

export default MyAccount;
