import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Support = () => {
  const HeaderContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2)
  }));

  const { t } = useTranslation();
  return (
    <Box sx={{ px: 3, width: '100%' }}>
      <HeaderContainer>
        <Typography variant="h6" fontWeight="normal">
          {t('Support')}
        </Typography>
      </HeaderContainer>
      <Box sx={{ px: 3 }}>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum aliquam ipsam voluptatum
        nulla saepe commodi minus eligendi at numquam tempora sequi corporis itaque nisi deleniti
        odit ducimus temporibus similique error culpa natus laudantium, fugit laborum animi vel.
        Numquam ratione molestias asperiores illum, nulla nam sit praesentium adipisci a nihil
        molestiae?
      </Box>
    </Box>
  );
};
export default Support;
