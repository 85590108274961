import { Menu, MenuItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { UserSquare } from '../../assets/svg/UserSquare';
import { SignOut } from '../../assets/svg/SignOut';
import { CreditCard } from '../../assets/svg/CreditCard';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import { selectAccount } from '../../store/slices/accountSlice';
import { useTranslation } from 'react-i18next';
const StyledListItemText = styled(ListItemText)({
  '.MuiTypography-root': {
    color: '#C40006D3'
  }
});

const ProfileDropdown = ({ anchorEl, setAnchorEl, open, handleMouseLeave }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useSelector(selectAccount);
  const handleMenuClick = (action) => {
    if (action === 'profile') {
      if (data && data?.user_type === 'tenant') {
        return navigate('/my-account?section=searches');
      } else {
        return navigate('/owner-account?section=dashboard');
      }
    }

    if (action === 'payment') return navigate('/my-account?section=subscription');

    authService.logout();
    navigate('/');
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="profile-menu"
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        onMouseLeave: handleMouseLeave
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 31,
            height: 32,
            ml: -0.5,
            mr: 1
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <MenuItem onClick={() => handleMenuClick('profile')}>
        <ListItemIcon sx={{ minWidth: '28px!important' }}>
          <UserSquare />
        </ListItemIcon>
        <ListItemText>{t('Profile')}</ListItemText>
      </MenuItem>
      {data?.user_type === 'tenant' && (
        <MenuItem onClick={() => handleMenuClick('payment')}>
          <ListItemIcon sx={{ minWidth: '28px!important' }}>
            <CreditCard />
          </ListItemIcon>
          <ListItemText>{t('Payment')}</ListItemText>
        </MenuItem>
      )}
      <MenuItem onClick={() => handleMenuClick('logout')}>
        <ListItemIcon sx={{ minWidth: '28px!important' }}>
          <SignOut />
        </ListItemIcon>
        <StyledListItemText>{t('Log out')}</StyledListItemText>
      </MenuItem>
    </Menu>
  );
};

export default ProfileDropdown;
