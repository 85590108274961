import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const authService = {
  initiateGoogleLogin: async (userType) => {
    try {
      const redirect_url = process.env.REACT_APP_GOOGLE_REDIRECT_URL;
      const response = await axios.get(`${API_URL}/auth/login/google`, {
        params: { user_type: userType, redirect_url }
      });

      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  handleGoogleLoginCallback: async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/login/google/callback`);
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Existing Google Sign-In method (you may want to remove or update this)
  googleSignIn: async (idToken) => {
    try {
      const response = await axios.post(`${API_URL}/auth/google`, { idToken });
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Regular email/password login
  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password
      });
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // User registration
  register: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/auth/register`, userData);
      if (response.data) {
        console.log('Registration successful:', JSON.stringify(response.data));
        // authService.login(userData.email, userData.password);
        return response.data;
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Logout
  logout: () => {
    localStorage.removeItem('user');
    localStorage.removeItem('plan');
    localStorage.removeItem('input');
  },

  // Get current user
  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },

  // Check if user is authenticated
  isAuthenticated: () => {
    const user = authService.getCurrentUser();
    return !!user && !!user.access_token;
  },

  // Get authentication token
  getToken: () => {
    const user = authService.getCurrentUser();
    return user ? user.access_token : null;
  },

  // Refresh token (if your backend supports it)
  refreshToken: async () => {
    try {
      const user = authService.getCurrentUser();
      if (user && user.refresh_token) {
        const response = await axios.post(`${API_URL}/auth/refresh-token`, {
          refresh_token: user.refresh_token
        });
        if (response.data.access_token) {
          user.access_token = response.data.access_token;
          localStorage.setItem('user', JSON.stringify(user));
        }
        return response.data;
      }
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // -------------------------------------------------------------------------//
  // RESET PASSWORD
  requestResetPassword: async (email) => {
    if (!email) {
      throw new Error('Email is required to request a password reset.');
    }

    try {
      const response = await axios.post(`${API_URL}/auth/reset-password/request`, { email });

      if (response.data) {
        console.log(
          'Reset password request was successfully sended:',
          JSON.stringify(response.data)
        );
        return { ...response.data, status: response.status };
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  verifyResetPassword: async (token) => {
    if (!token) {
      throw new Error('Token is required to verify a password reset.');
    }

    try {
      const response = await axios.post(`${API_URL}/auth/reset-password/verify`, { token });

      if (response.data) {
        console.log(
          'Reset password request was successfully sended:',
          JSON.stringify(response.data)
        );
        return { ...response.data, status: response.status };
      }

      return { ...response.data, status: response.status };
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  confirmResetPassword: async ({ token, new_password }) => {
    if (!token || !new_password) {
      throw new Error('Token and new password are required to password reset.');
    }

    try {
      const response = await axios.post(`${API_URL}/auth/reset-password/confirm`, {
        token,
        new_password
      });

      if (response.data) {
        console.log(
          'Reset password request was successfully sended:',
          JSON.stringify(response.data)
        );
        return { ...response.data, status: response.status };
      }
      return { ...response.data, status: response.status };
    } catch (error) {
      throw error.response?.data || error;
    }
  }
};

export default authService;
